import React, { Component } from "react"
import { Link } from "gatsby"
import route from "../libraries/route"
import images from "../assets/images"
import home from "../assets/data/home"
import Responsive from "../libraries/responsive"
import { Helmet } from "react-helmet"
import TopMenu from "../components/TopMenu"
import BottomMenu from "../components/BottomMenu"

class Home extends Component {
  render() {
    const {
      meta_title,
      meta_description,
      description_title,
      description_founder,
      description_subtitle,
      contact_us,
      scope,
      carport,
      gazebo,
    } = home
    return (
      <>
        <TopMenu />
        <div className="home">
          <Helmet title={meta_title}>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
          </Helmet>
          <Responsive device="desktop">
            <main className="home-desktop">
              <article className="description">
                <section className="description-image" />
                <section className="description-text">
                  <h1>{description_title}</h1>
                  <h3>{description_founder}</h3>
                  <h4>{description_subtitle}</h4>
                  <span>
                    <p>
                      Lericas crea soluzioni <b>naturali</b> di costruzione{" "}
                      <b>artigianale</b> per il massimo del comfort e del
                      design. Fra i nostri prodotti più richiesti troviamo
                      coperture naturali, parcheggi auto, gazebo, ombrelloni e
                      staccionate. Siamo inoltre specializzati nella lavorazione
                      di <b>erica scoparia</b> per la realizzazione di scope e
                      rivestimenti in ambito turistico e privato. Forniamo anche
                      di un vasto assortimento di coperture con stuoie in erica,
                      bambù e altri materiali. Soddisfaremo ogni vostra
                      richiesta e vi forniremo la migliore soluzione con
                      preventivi su misura e gratuiti.
                    </p>
                    <Link className="white-link-button" to={route("contacts")}>
                      {contact_us}
                    </Link>
                  </span>
                </section>
              </article>
              <article className="products-preview">
                <Link
                  className="product-preview"
                  to={route("product", { productID: "gazebo" })}
                >
                  <img src={images.gazebo1} alt="img" />
                  <div className="product-preview-text">
                    <h4>{gazebo}</h4>
                  </div>
                </Link>
                <Link
                  className="product-preview"
                  to={route("product", { productID: "carport" })}
                >
                  <img src={images.carport1} alt="img" />
                  <div className="product-preview-text">
                    <h4>{carport}</h4>
                  </div>
                </Link>
                <Link
                  className="product-preview"
                  to={route("product", { productID: "scope" })}
                >
                  <img src={images.scope1} alt="img" />
                  <div className="product-preview-text">
                    <h4>{scope}</h4>
                  </div>
                </Link>
              </article>
            </main>
          </Responsive>
          <Responsive device="not-desktop">
            <main className="home-mobile">
              <article className="description">
                <section className="image" />
                <section className="text">
                  <h1>{description_title}</h1>
                  <h3>{description_founder}</h3>
                  <h4>{description_subtitle}</h4>
                  <span>
                    <Link className="white-link-button" to={route("contacts")}>
                      {contact_us}
                    </Link>
                  </span>
                </section>
              </article>
              <article className="products-preview">
                <Link
                  className="product-preview"
                  to={route("product", { productID: "gazebo" })}
                >
                  <img src={images.gazebo1} alt="img" />
                  <div className="product-preview-text">
                    <h4>{gazebo}</h4>
                  </div>
                </Link>
                <Link
                  className="product-preview"
                  to={route("product", { productID: "carport" })}
                >
                  <img src={images.carport1} alt="img" />
                  <div className="product-preview-text">
                    <h4>{carport}</h4>
                  </div>
                </Link>
                <Link
                  className="product-preview"
                  to={route("product", { productID: "scope" })}
                >
                  <img src={images.scope1} alt="img" />
                  <div className="product-preview-text">
                    <h4>{scope}</h4>
                  </div>
                </Link>
              </article>
            </main>
          </Responsive>
        </div>
        <BottomMenu />
      </>
    )
  }
}

export default Home
