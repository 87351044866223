export default {
  meta_title: "Lericas - Scope di Erica, Coperture, Strutture in Legno",
  meta_description:
    "Lericas fornisce le migliori soluzioni artigianali - Scope di Erica - Coperture in Erica Bamboo e Guaina Ardesiata - Strutture in Legno Castagno Abete e Pino",
  description_title: "Lericas",
  description_founder: "di Strazza Luigi",
  description_subtitle: `Scope in erica, Coperture in Erica, Gazebo Artigianale ed altre
  strutture in legno`,
  contact_us: "contattaci",
  scope: "Scope In Erica",
  carport: "Parcheggi auto",
  gazebo: "Gazebo",
}
